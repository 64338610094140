.app {
  position: relative;
  width: 390px;
  height: 660px;
  margin: auto;
  /* background-color: white; */
}

.freeze {
  pointer-events: none;
}