.startScreen {
  position: absolute;
  top: 0;
  width: 390px;
  height: 660px;
  background-image: url("/public/assets/startScreen.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 15;
}

.startScreenLogo {
  display: block;
  position: absolute;
  right: 21px;
  top: 25px;
  width: 52px;
  height: 52px;
  background-image: url("/public/assets/alfaLogoRed.png");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.startScreenButton {
  position: absolute;
  bottom: 0px;
  width: 333px;
  margin: 0 28px 24px 28px;
  font-size: 18px;
}
