.comixStickerContainer {
  position: relative;
  margin: auto;
  width: 390px;
  height: 660px;
  margin: auto;
  z-index: 17;
  pointer-events: none;
}

.comixStickerBackground {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 16;
  pointer-events: auto;
}

.comixSticker {
  position: absolute;
  left: 16px;
  top: 136px;
  width: 360px;
  height: 320px;
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: auto;
}

.comixStickerImg-shark {
  background-image: url("/public/assets/comixSticker1.png");
}
.comixStickerImg-owl {
  background-image: url("/public/assets/comixSticker2.png");
}
.comixStickerImg-aloe {
  background-image: url("/public/assets/comixSticker3.png");
}

.comixStickerClose {
  position: absolute;
  top: 10px;
  right: 35px;
  width: 36px;
  height: 36px;
  background-image: url("/public/assets/comixStickerClose.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: unset;
  border: none;
  cursor: pointer;
}

.comixStickerApply {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /* padding-bottom: 4px; */
  position: absolute;
  height: 52px;
  bottom: 8px;
  left: 26px;
  width: 338px;
  font-size: 18px;
  text-decoration: none;
  pointer-events: auto;
}
