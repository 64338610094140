.elevatorContainer {
  position: absolute;
  bottom: 0;
  width: 390px;
  height: 580px;
  margin: auto;
  background-color: #ffffff;
}

.elevatorScreen {
  position: absolute;
  width: 334px;
  height: 502px;
  left: 28px;
  background-image: url("/public/assets/elevator.png");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 12px;
  overflow: hidden;
}

.elevatorLeftDoor {
  position: absolute;
  width: 0%;
  height: 502px;
  background-color: #d6e6ff;
  z-index: 13;
  border-radius: 12px 0 0 12px;
  box-shadow: 5px 0px 5.5px rgba(0, 0, 0, 0.4);
  transition: width 500ms;
}
.elevatorRightDoor {
  position: absolute;
  right: 0;
  width: 0%;
  height: 502px;
  background-color: #d6e6ff;
  z-index: 13;
  border-radius: 0 12px 12px 0;
  transition: width 500ms;
}
.elevatorDoorTransition {
  width: 50%;
}

.elevatorScreen > img {
  position: absolute;
  object-fit: contain;
  overflow: visible;
  padding: 10px;
  margin: -10px;
}
.elevatorScreenHover {
  /* -webkit-filter: drop-shadow(3px 0px 0 #EF3124) drop-shadow(0px 3px 0 #EF3124) drop-shadow(-3px -0px 0 #EF3124) drop-shadow(-0px -3px 0 #EF3124); */
  /* filter: drop-shadow(3px 0px 0 #EF3124) drop-shadow(0px 3px 0 #EF3124) drop-shadow(-3px -0px 0 #EF3124) drop-shadow(-0px -3px 0 #EF3124); */
  filter: url(#svgStrokeOutside);
}

.owl {
  width: 159px;
  height: 180px;
  top: 320px;
  left: 2px;
  z-index: 10;
}
.crab {
  width: 214px;
  height: 193px;
  top: 310px;
  right: 5px;
  z-index: 9;
}

.lemon {
  width: 140px;
  height: 279px;
  top: 199px;
  right: 10px;
  z-index: 8;
}
.beaver {
  width: 144px;
  height: 303px;
  top: 188px;
  right: 97px;
  z-index: 7;
}
.cat {
  width: 142px;
  height: 225px;
  top: 115px;
  right: 2px;
  z-index: 6;
}
.circulus {
  width: 126px;
  height: 358px;
  top: 127px;
  right: 206px;
  z-index: 5;
}
.aloe {
  width: 140px;
  height: 408px;
  top: 69px;
  right: 102px;
  z-index: 4;
}
.shark {
  width: 124px;
  height: 231px;
  top: 35px;
  right: 197px;
  z-index: 3;
}
.rice {
  width: 123px;
  height: 220px;
  top: 13px;
  right: 7px;
  z-index: 2;
}
.bulb {
  width: 99px;
  height: 152px;
  top: 0px;
  right: 116px;
  z-index: 1;
}

.elevatorControls {
  display: flex;
  justify-content: space-between;
  width: 334px;
  position: absolute;
  bottom: 0;
  margin: 0 28px 14px 28px;
}

.elevatorExit {
  width: 52px;
  height: 52px;
  border: none;
  background-image: url("/public/assets/exitButton.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white;
  cursor: pointer;
}

.elevatorEnter {
  width: 266px;
  height: 52px;
  font-size: 18px;
}

.mapImg {
  position: absolute;
  width: 334px;
  height: 502px;
  border-radius: 12px;
  z-index: 12;
}

.area {
  cursor: pointer;
}
