.comixSliderContainer {
  position: absolute;
  bottom: 0;
  width: 392px;
  height: 580px;
  margin: 0 -1px;
  z-index: 15;
  background-color: #ffffff;
}

.comixSliderCardContainer {
  position: absolute;
  width: 334px;
  height: 502px;
  left: 28px;
  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
}

.comixSliderCard {
  position: relative;
  width: 334px;
  height: 502px;
  border-radius: 12px;
}

.comixSliderCardLeftDoor {
  position: absolute;
  width: 50%;
  height: 502px;
  background-color: #d6e6ff;
  z-index: 13;
  border-radius: 12px 0 0 12px;
  box-shadow: 5px 0px 5.5px rgba(0, 0, 0, 0.4);
  animation: 0.75s forwards openComixSliderCardDoor;
}

.comixSliderCardRightDoor {
  position: absolute;
  right: 0;
  width: 50%;
  height: 502px;
  background-color: #d6e6ff;
  z-index: 13;
  border-radius: 0 12px 12px 0;
  animation: 0.75s forwards openComixSliderCardDoor;
}

@keyframes openComixSliderCardDoor {
  0% {
    width: 50%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 0%;
  }
}

.comixSliderCardButton {
  position: absolute;
  right: 28px;
  bottom: 14px;
  width: 266px;
  height: 52px;
  cursor: pointer;
  font-size: 18px;
}

.comixSliderContainer .slick-slider {
  position: relative;
}

.comixSliderContainer .slick-dots {
  bottom: 10px;
}

.comixSliderContainer .slick-dots li {
  bottom: 6px;
}

.comixSliderContainer .slick-dots li {
  width: 14px;
  height: 14px;
  margin: 0;
}

.comixSliderContainer.slick-dots li button:before {
  font-size: 7px;
}

.comixSliderContainer .slick-dots li.slick-active button:before {
  color: #ffffff;
  opacity: 100%;
}
.comixSliderContainer .slick-dots li button:before {
  color: #ffffff;
  opacity: 50%;
}

.comixSliderContainer .slick-arrow {
  width: 167px;
  height: 502px;
}

.comixSliderContainer .slick-prev {
  left: 0;
  z-index: 2;
}
.comixSliderContainer .slick-prev::before {
  content: "";
  font-family: none;
}


.comixSliderContainer .slick-next {
  right: 0;
  z-index: 2;
}
.comixSliderContainer .slick-next::before {
  content: "";
  font-family: none;
}
