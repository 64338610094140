.buttonRed {
  height: 52px;
  border-radius: 12px;
  background-color: #ef3124;
  border: none;
  font-family: "Styrene B LC Bold";
  color: white;
  cursor: pointer;
  padding-bottom: 7px;
}
