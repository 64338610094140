.endScreenPositive {
  position: relative;
  width: 390px;
  height: 829px;
  margin: auto;
  background-image: url("/public/assets/endingPositive.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #ffffff;
}

.endScreenNegative {
  position: relative;
  width: 390px;
  height: 829px;
  margin: auto;
  background-image: url("/public/assets/endingNegative.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #ffffff;
}

.endScreenLogo {
  display: block;
  position: absolute;
  right: 28px;
  top: 16px;
  width: 52px;
  height: 52px;
  background-image: url("/public/assets/alfaLogoRed.png");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.endScreenCardPositive {
  position: absolute;
  left: 28px;
  bottom: 222px;
  width: 334px;
  height: 260px;
  background-color: #d6e6ff;
  border-radius: 12px;
}

.endScreenCardNegative {
  position: absolute;
  left: 28px;
  bottom: 222px;
  width: 334px;
  height: 275px;
  background-color: #d6e6ff;
  border-radius: 12px;
}

.endScreenCardText {
  height: 128px;
  width: 286px;
  margin: 16px 20px;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  font-family: "Styrene B LC Regular";
  font-size: 15px;
}

.endScreenSliderContainer {
  position: absolute;
  bottom: 0;
  width: 334px;
}

.endScreenSliderContainer .slick-slider {
  position: relative;
  height: 80px;
  margin: 10px 10px 10px 20px;
}

.endScreenSliderContainer .slick-dots {
  bottom: 0;
}

.endScreenSliderContainer .slick-dots li {
  bottom: 6px;
}

.endScreenSliderContainer .slick-dots li {
  width: 14px;
  height: 14px;
  margin: 0;
}

.endScreenSliderContainer.slick-dots li button:before {
  font-size: 7px;
}

.endScreenSliderContainer .slick-dots li.slick-active button:before {
  color: #505759;
  opacity: 100%;
}
.endScreenSliderContainer .slick-dots li button:before {
  color: #ffffff;
  opacity: 50%;
}

.endScreenSliderButton {
  padding-bottom: 7px;
  width: 294px;
  height: 52px;
  background-color: #ffffff;
  border-radius: 12px;
  font-family: "Styrene B LC Bold";
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  border: none;
}

.endScreenButton {
  display: block;
  text-align: center;
  padding-top: 9px;
  padding-bottom: 9px;
  position: absolute;
  bottom: 50px;
  left: 28px;
  width: 334px;
  height: 33px;
  font-size: 16px;
  text-decoration: none;
}
