.sliderContainer {
  position: absolute;
  bottom: 0;
  width: 390px;
  height: 580px;
  margin: auto;
  background-color: #ffffff;
  z-index: 14;
}

.sliderCardContainer {
  position: relative;
  height: 580px;
}

.sliderCard {
  width: 334px;
  height: 476px;
  margin: 0 5px;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 24px;
  height: 36px;
  width: 36px;
  background-image: url("/public/assets/closeButton.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
}

.hiddenCloseButton {
  position: absolute;
  top: 16px;
  right: 44px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 18px;
  border: none;
  z-index: 3;
}

.hiddenButton {
  position: absolute;
  right: 7px;
  bottom: 13px;
  height: 54px;
  width: 268px;
  border: unset;
  background-color: unset;
  cursor: pointer;
  pointer-events: auto;
}

.sliderContainer .slick-slider {
  position: relative;
}

.sliderContainer .slick-dots {
  bottom: 77px;
}

.sliderContainer .slick-dots li {
  bottom: 6px;
}

.sliderContainer .slick-dots li {
  width: 14px;
  height: 14px;
  margin: 0;
}

.sliderContainer.slick-dots li button:before {
  font-size: 7px;
}

.sliderContainer .slick-arrow {
  width: 197px;
}

.sliderContainer .slick-prev {
  top: 240px;
  left: 0;
  height: 480px;
  z-index: 2;
}
.sliderContainer .slick-prev::before {
  content: "";
  font-family: none;
}

.sliderContainer .slick-next {
  top: 240px;
  right: 0;
  height: 480px;
  z-index: 2;
}
.sliderContainer .slick-next::before {
  content: "";
  font-family: none;
}

.sliderAnswer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 35px;
  bottom: 112px;
  width: 319px;
  height: 179px;
  background-color: #ffffff;
  border-radius: 12px;
}

.sliderAnswerResult {
  width: 171px;
  height: 19px;
  padding: 10px 0;
  margin: 20px 0 16px 0;

  background-color: black;
  border-radius: 12px;
  text-align: center;
  line-height: 16px;
  color: white;
}

.sliderAnswerText {
  max-width: 260px;
  text-align: center;
  line-height: 18px;
}

.sliderControls {
  display: flex;
  justify-content: space-between;
  width: 334px;
  position: absolute;
  bottom: 0;
  margin: 0 28px 14px 28px;
}

.sliderExit {
  position: absolute;
  left: 28px;
  bottom: 14px;
  width: 52px;
  height: 52px;
  border: none;
  background-image: url("/public/assets/exitButton.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white;
  cursor: pointer;
}

.sliderEnter {
  position: absolute;
  right: 28px;
  bottom: 14px;
  width: 266px;
  height: 52px;
  pointer-events: none;
  cursor: none;
  font-size: 18px;
}
