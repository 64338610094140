.popup {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 12;
}

.popupTransition {
  animation: 0.75s forwards steps(1) showPopup;
}

.popupContainer {
  position: relative;
  width: 390px;
  height: 660px;
  margin: auto;
  z-index: 12;
}

@keyframes showPopup {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popupElevator {
  position: absolute;
  width: 334px;
  height: 502px;
  left: 28px;
  top: 82px;
  background-image: url("/public/assets/elevator.png");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 12px;
  overflow: hidden;
}

.popupElevator > img {
  position: absolute;
  object-fit: contain;
}

.popupImg1 {
  top: 20px;
  position: absolute;
  width: 250px;
  z-index: 12;
}

.popupImg2 {
  position: absolute;
  top: 19px;
  left: 28px;
  width: 334px;
  height: 565px;
  z-index: 12;
}

.popupImg3 {
  position: absolute;
  top: 82px;
  left: 27px;
  width: 334px;
  height: 502px;
  z-index: 12;
}

.popupImg4 {
  position: absolute;
  top: 82px;
  left: 13px;
  width: 347.4px;
  height: 502px;
  z-index: 12;
}

.popupSkip {
  position: absolute;
  bottom: 75px;
  left: 28px;
  width: 334px;
  height: 120.82px;
  z-index: 12;
  cursor: pointer;
}

.popupButton {
  position: absolute;
  bottom: 14px;
  left: 28px;
  width: 334px;
  font-size: 18px;
}

.popupButtonTransition {
  animation: 0.75s forwards steps(1) showPopupButton;
}

@keyframes showPopupButton {
  from {
    opacity: 0;
    pointer-events: none;
  }
  to {
    opacity: 1;
    pointer-events: auto;
  }
}
