.floor {
  position: absolute;
  top: 0;
  width: 335px;
  height: 52px;
  overflow: hidden;
  margin: 16px 28px 12px 28px;
}

.floorBar {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 52px;
  text-align: center;
}

.floorNumberBox {
  width: 52px;
  height: 52px;
  border-radius: 12px;
  background: #d6e6ff;
}

.floorNameBox {
  width: 214px;
  height: 52px;
  text-align: left;
  font-size: 18px;
  line-height: 18px;
  border-radius: 12px;
  background: #d6e6ff;
}

.floorLogoBox {
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 12px;
  background: #d6e6ff;
}

.floorBarData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  text-align: center;
  transform: translate(0, -104px);
  transition: margin 750ms;
}

.floorBarTransition {
  transform: translate(0, -52px);
  transition: transform 750ms;
}

.initialFloorBarTransition {
  margin-top: -52px;
}

.floorNumber {
  position: relative;
  top: -3px;
  width: 52px;
  border-radius: 12px;
  font-family: "Styrene B LC Regular";
  font-size: 40px;
}

.floorName {
  position: relative;
  top: -2px;
  left: 10px;
  width: 214px;
  border-radius: 12px;
  text-align: left;
  font-family: "Styrene B LC Medium";
  font-size: 18px;
  line-height: 18px;
}

.floorLogo {
  position: relative;
  top: 3px;
  left: -1px;
  align-items: center;
  width: 52px;
  border-radius: 12px;
}
