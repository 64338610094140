body {
  display: flex;
  margin: auto;
  justify-content: center;
  /* min-height: 100vh; */
  min-width: 390px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  /* margin: -5px 0 0 0; */
  font-family: "Styrene B LC Bold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bgGrad {
  background: linear-gradient(
    160.54deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(216, 232, 255, 1) 100%
  );
}

.root {
  display: flex;
  align-items: center;
}

.scale {
  transform: scale(1);
  transform-origin: center;
}

@media only screen and (max-height : 700px) and (orientation: landscape) {
  .scale {
    transform: scale(0.8) translateY(-5%);
  }
}

@media only screen and (orientation: portrait) {
  /* .scale {
    transform-origin: top;
  } */
}
@media only screen and (min-height : 600px) and (orientation: portrait) {
  /* .root {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
  } */

  .app {
    margin: 0px !important;
  }
}

@media only screen and (min-height : 1000px) {
  .scale {
    transform: scale(1.7);
  }
}


@font-face {
  /* ascent-override: 95%; */
  font-family: "Styrene B LC Bold";
  src: url(../public/fonts/StyreneBLC-Bold.ttf);
}
@font-face {
  font-family: "Styrene B LC Medium";
  src: url(../public/fonts/StyreneBLC-Medium.ttf);
}
@font-face {
  font-family: "Styrene B LC Regular";
  src: url(../public/fonts/StyreneBLC-Regular.ttf);
}

/* @font-face {
  font-family: "Styrene B LC Bold";
  src: url(../public/fonts/StyreneAWeb-Bold.ttf);
}
@font-face {
  font-family: "Styrene B LC Medium";
  src: url(../public/fonts/StyreneAWeb-Medium.ttf);
}
@font-face {
  font-family: "Styrene B LC Regular";
  src: url(../public/fonts/StyreneAWeb-Regular.ttf);
} */

